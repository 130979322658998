import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const REDUX_COLLECTION_NAME = "sharedState";
const INITIAL_ACTION = `${REDUX_COLLECTION_NAME}/INITIAL`;
const UPDATE_ACTION = `${REDUX_COLLECTION_NAME}/UPDATE`;

const initial = {};

function reducer(state = initial, action) {
	const { type, data } = action;
	switch (type) {
		case INITIAL_ACTION:
		case UPDATE_ACTION:
			return {
				...state,
				...data,
			};
		default:
			return state;
	}
}

const reducers = {
	[REDUX_COLLECTION_NAME]: reducer,
};

const initialAction = (name, value) => {
	return {
		type: INITIAL_ACTION,
		data: {
			[name]: value,
		},
	};
};

const updateAction = (name, value) => {
	return {
		type: UPDATE_ACTION,
		data: {
			[name]: value,
		},
	};
};

const useReduxSharedState = (name, defaultValue) => {
	const dispatch = useDispatch();
	const value = useSelector(state => state[REDUX_COLLECTION_NAME]?.[name]);
	const setValue = newValue => {
		return dispatch(updateAction(name, newValue));
	};
	useEffect(() => {
		if (typeof value === "undefined" || value === null) {
			dispatch(initialAction(name, defaultValue));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [name, defaultValue, value]);
	return [value, setValue];
};

export { reducer, reducers };
export default useReduxSharedState;
