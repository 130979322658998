import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import {
// 	createStateSyncMiddleware,
// 	initMessageListener,
// 	// initStateWithPrevTab,
// } from "redux-state-sync";
import { composeWithDevTools } from "redux-devtools-extension";
import dynamicMiddlewares from "redux-dynamic-middlewares";
import reducer from "./modules";
import apiMiddleware from "./middleware/apiMiddleware";
// import { UPDATE_PROFILE_AVATAR, UPDATE_PROFILE_DATA } from "./modules/auth";

export default function configureStore(preloadedState) {
	const composeEnhancers = composeWithDevTools({});
	const middleware = [thunk, apiMiddleware, dynamicMiddlewares];
	// const middleware = [thunk, apiMiddleware];
	const store = createStore(
		reducer,
		preloadedState,
		composeEnhancers(applyMiddleware(...middleware))
	);
	// initMessageListener(store);
	// initStateWithPrevTab(store);
	// if (module.hot) {
	// 	module.hot.accept("./modules", () => {
	// 		store.replaceReducer(require("./modules").default);
	// 	});
	// }
	return store;
}
