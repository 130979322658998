// import { api } from "../../config/axios";
import AnnouncementService from "../../services/AnnouncementService";
// import defaultMapper from "../utils/defaultMapper";

const GET_OFFER = "offer/GET_OFFER";
const GET_OFFER_SUCCESS = "offer/GET_OFFER_SUCCESS";
const GET_OFFER_FAIL = "offer/GET_OFFER_FAIL";

const EDIT_OFFER = "offer/EDIT_OFFER";
const EDIT_OFFER_SUCCESS = "offer/EDIT_OFFER_SUCCESS";
const EDIT_OFFER_FAIL = "offer/EDIT_OFFER_FAIL";

const initial = {};

function reducer(state = initial, action) {
	// console.log("action", action);
	switch (action.type) {
		case GET_OFFER:
			return {
				...state,
			};
		case GET_OFFER_SUCCESS:
			return {
				...state,
				...action.result.data.data,
			};
		case GET_OFFER_FAIL:
			return {
				...state,
			};
		case EDIT_OFFER:
			return {
				...state,
			};
		case EDIT_OFFER_SUCCESS:
			return {
				...state,
				// ...action.result,
			};
		case EDIT_OFFER_FAIL:
			return {
				...state,
			};
		default:
			return state;
	}
}

export function getOffer(id) {
	return {
		types: [GET_OFFER, GET_OFFER_SUCCESS, GET_OFFER_FAIL],
		promise: () =>
			AnnouncementService.getById(id, {
				includes: ["region", "city"].join(","),
			}),
	};
}

export function editOffer(id, data) {
	return {
		types: [EDIT_OFFER, EDIT_OFFER_SUCCESS, EDIT_OFFER_FAIL],
		promise: () => AnnouncementService.edit(id, data),
	};
}

export default reducer;
