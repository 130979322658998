import { lazy } from "react";
import i18next from "i18next";
import cookies from "react-cookies";
// import /* webpackMode: "lazy", webpackChunkName: "locale/pl" */ pl from "../translations/pl";
// import /* webpackMode: "lazy", webpackChunkName: "locale/en" */ en from "../translations/en";

const i18n = i18next.createInstance();

// load resources
// const languageResources = {
// 	pl: () =>
// 		import(
// 			/* webpackMode: "lazy", webpackChunkName: "locale/pl" */
// 			"../translations/pl"
// 		),
// 	en: () =>
// 		import(
// 			/* webpackMode: "lazy", webpackChunkName: "locale/en" */
// 			"../translations/en"
// 		),
// };
// const loadLangResources = lang => {
// 	const fn = languageResources[lang];
// 	if (typeof fn === "function") {
// 		fn()
// 			.then(module => {
// 				const namespaces = module.default;
// 				// const promises = new Promise();
// 				Object.keys(namespaces).forEach(namespace => {
// 					const content = namespaces[namespace];
// 					i18n.addResourceBundle(lang, namespace, content);
// 					console.log("namespace", namespace);
// 				});
// 			})
// 			.then(() => {
// 				console.log("load");
// 				i18n.reloadResources();
// 			});
// 	}
// };

let currentLang = cookies.load("lang");
const languages = ["pl", "en"];
if (!currentLang && languages.includes(currentLang) === false) {
	currentLang = languages[0];
	cookies.save("lang", currentLang, {
		path: "/",
	});
}

i18n.init({
	debug: false,
	interpolation: { escapeValue: false },
	lng: currentLang,
	resources: [],
	// resources: {
	// 	pl,
	// 	en,
	// },
	react: {
		useSuspense: true,
		wait: true,
	},
	transSupportBasicHtmlNodes: true,
	transKeepBasicHtmlNodesFor: ["br", "strong", "b", "i", "u", "p"],
});

i18n.on("languageChanged", language => {
	cookies.save("lang", language, {
		path: "/",
	});
});

const loadPolish = () =>
	import(
		/* webpackMode: "lazy", webpackChunkName: "locale/pl" */ "../translations/pl"
	);
const loadEnglish = () =>
	import(
		/* webpackMode: "lazy", webpackChunkName: "locale/en" */ "../translations/en"
	);

loadPolish().then(resources => {
	const namespaces = resources.default;
	Object.keys(namespaces).forEach(namespaceName => {
		i18n.addResourceBundle("pl", namespaceName, namespaces[namespaceName]);
	});
});

loadEnglish().then(resources => {
	const namespaces = resources.default;
	Object.keys(namespaces).forEach(namespaceName => {
		i18n.addResourceBundle("en", namespaceName, namespaces[namespaceName]);
	});
});

export default i18n;
