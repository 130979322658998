import { api } from "../config/axios";
import formDataCreator from "../helpers/formDataCreator";

const apiPrefix = "/announcement";

const getById = (id, params) => {
	const url = `${apiPrefix}/${id}`;
	return api.get(url, { params });
};

const get = params => {
	return api.get(apiPrefix, { params });
};

const remove = id => {
	const url = `${apiPrefix}/${id}`;
	return api.delete(url);
};

const removePermanent = id => {
	const url = `${apiPrefix}/${id}`;
	return api.delete(url, { params: { permanent: true } });
};

const addService = (id, { user }) => {
	const formData = new FormData();
	formData.append("user", user);
	return api.post(`${apiPrefix}/${id}/service`, formData);
};

const updateServiceStatus = (id, data) => {
	return api.put(`${apiPrefix}/${id}/service`, formDataCreator(data));
};

const manageGet = token => {
	return api.get(`${apiPrefix}/manage/${token}`);
};

const manageCancel = (token, { cancelReason }) => {
	return api.delete(`${apiPrefix}/manage/${token}`, {
		params: {
			cancelReason,
		},
	});
};

const create = data => {
	const url = apiPrefix;
	const formData = formDataCreator(data);
	return api.post(url, formData);
};

const edit = (id, data) => {
	const formData = new FormData();
	const dataEntries = Object.entries(data);
	dataEntries.forEach(item => {
		const [key, value] = item;
		if (value !== null && value !== "") {
			formData.append(key, value);
		}
	});
	return api.put(`${apiPrefix}/${id}`, formData);
};

// const uploadAttachmentUrl = token => `${apiPrefix}/upload/${token}`;

// const removeAttachment = (uuid, id) => {
// 	return api.delete(`${apiPrefix}/upload/${uuid}/${id}`);
// };

export default {
	getById,
	get,
	remove,
	removePermanent,
	addService,
	updateServiceStatus,
	manageGet,
	manageCancel,
	create,
	edit,
	// uploadAttachmentUrl,
	// removeAttachment,
};
