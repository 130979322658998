import { api } from "../config/axios";
// import formDataCreator from "../helpers/formDataCreator";

const apiPrefix = "/notification";

const get = params => {
	return api.get(apiPrefix, { params });
};

const getById = (id, params) => {
	return api.get(`${apiPrefix}/${id}`, {
		params,
	});
};

const getByToken = (token, params) => {
	return api.get(`${apiPrefix}/token/${token}`, {
		params,
	});
};

const markAllAsReaded = () => {
	return api.put(`${apiPrefix}`);
};

const remove = id => {
	return api.delete(`${apiPrefix}/${id}`);
};

const removeAll = () => {
	return api.delete(`${apiPrefix}`);
};

export default {
	get,
	getById,
	getByToken,
	markAllAsReaded,
	remove,
	removeAll,
};
