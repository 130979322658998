import config from "../config/config";

const getDefault = () => {
	return config.appName;
};

const generateTemplate = prevTitles => {
	const { appName, titlePartSeparator } = config;
	const parts = [];
	parts.push("%s");
	prevTitles.forEach(title => parts.push(title));
	parts.push(appName);
	return parts.join(` ${titlePartSeparator} `);
};

export { getDefault, generateTemplate };
