export default (data, filter) => {
	const _filter = filter || ["null", "undefined"];
	const formData = new FormData();
	Object.entries(data).forEach(([key, value]) => {
		let ok = true;
		if (_filter.length > 0) {
			if (
				_filter.includes("empty") &&
				(value === undefined ||
					value === null ||
					(typeof value === "string" && value.length === 0))
			) {
				ok = false;
			}
			if (_filter.includes("undefined") && value === undefined) {
				ok = false;
			}
			if (_filter.includes("null") && value === null) {
				ok = false;
			}
		}
		if (ok === true) {
			formData.append(key, value);
		}
	});
	return formData;
};
