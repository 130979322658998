import { parseBool } from "../helpers/parser";

const {
	NODE_ENV,
	REACT_APP_API_HOST: API_HOST,
	REACT_APP_API_URL: API_URL,
	REACT_APP_CDN_HOST: CDN_HOST,
	REACT_APP_CDN_URL: CDN_URL,
	REACT_APP_WEBPUSH_VAPID_PUBLIC: WEBPUSH_VAPID_PUBLIC,
	REACT_APP_LOGROCKET_ID: LOGROCKET_ID,
	REACT_APP_GOOGLE_GTAG_DEBUG: _GOOGLE_GTAG_DEBUG,
	REACT_APP_GOOGLE_GTAG_KEY: GOOGLE_GTAG_KEY,
	// REACT_APP_GOOGLE_ANALITICS_KEY,
	// REACT_APP_GOOGLE_ANALITICS_DEBUG,
} = process.env;
const GOOGLE_GTAG_DEBUG = parseBool(_GOOGLE_GTAG_DEBUG || false);
const IS_DEV = NODE_ENV === "development";
const IS_PROD = NODE_ENV === "production";
export {
	NODE_ENV,
	IS_DEV,
	IS_PROD,
	API_HOST,
	API_URL,
	CDN_HOST,
	CDN_URL,
	WEBPUSH_VAPID_PUBLIC,
	LOGROCKET_ID,
	GOOGLE_GTAG_DEBUG,
	GOOGLE_GTAG_KEY,
	// REACT_APP_GOOGLE_ANALITICS_KEY as GOOGLE_ANALITICS_KEY,
	// REACT_APP_GOOGLE_ANALITICS_DEBUG as GOOGLE_ANALITICS_DEBUG,
};
export default {
	NODE_ENV,
	IS_DEV,
	IS_PROD,
	API_HOST,
	API_URL,
	CDN_HOST,
	CDN_URL,
	WEBPUSH_VAPID_PUBLIC,
	LOGROCKET_ID,
	GOOGLE_GTAG_DEBUG,
	GOOGLE_GTAG_KEY,
	// GOOGLE_ANALITICS_KEY: REACT_APP_GOOGLE_ANALITICS_KEY,
	// GOOGLE_ANALITICS_DEBUG: REACT_APP_GOOGLE_ANALITICS_DEBUG,
};
