// import { api } from "../../config/axios";
import NotificationService from "../../services/NotificationService";
import { getRandomInt } from "../utils/random";

const GET_UNREADED_COUNT = "notification/GET_UNREADED_COUNT";
const GET_UNREADED_COUNT_SUCCESS = "notification/GET_UNREADED_COUNT_SUCCESS";
const GET_UNREADED_COUNT_FAIL = "notification/GET_UNREADED_COUNT_FAIL";

const GET_LATEST = "notification/GET_LATEST";
const GET_LATEST_SUCCESS = "notification/GET_LATEST_SUCCESS";
const GET_LATEST_FAIL = "notification/GET_LATEST_FAIL";

const READ_NOTIFICATION = "offer/READ_NOTIFICATION";

const initial = {
	unreaded: {
		value: 0,
		pending: false,
	},
	latest: {
		data: [],
		pending: false,
		error: null,
	},
};

function reducer(state = initial, action) {
	switch (action.type) {
		case GET_UNREADED_COUNT:
			return {
				...state,
				unreaded: {
					...state.unreaded,
					pending: true,
					error: null,
				},
			};
		case GET_UNREADED_COUNT_SUCCESS:
			return {
				...state,
				unreaded: {
					...state.unreaded,
					pending: false,
					value: action.result?.data?.data,
				},
			};
		case GET_UNREADED_COUNT_FAIL:
			return {
				...state,
				unreaded: {
					...state.unreaded,
					pending: false,
					error: action.error,
				},
			};
		case GET_LATEST:
			return {
				...state,
				latest: {
					...state.latest,
					pending: true,
					error: null,
				},
			};
		case GET_LATEST_SUCCESS:
			return {
				...state,
				latest: {
					...state.latest,
					data: action.result.data,
				},
			};
		case GET_LATEST_FAIL:
			return {
				...state,
				latest: {
					...state.latest,
					pending: false,
					error: action.error,
				},
			};
		case READ_NOTIFICATION:
			return {
				...state,
			};
		default:
			return state;
	}
}

export function getUnreadedCount() {
	return {
		types: [
			GET_UNREADED_COUNT,
			GET_UNREADED_COUNT_SUCCESS,
			GET_UNREADED_COUNT_FAIL,
		],
		promise: () =>
			NotificationService.get({
				"filter[date_readed:null]": "",
				count: "",
			}),
	};
}

export function getNotificationLatest() {
	return {
		types: [GET_LATEST, GET_LATEST_SUCCESS, GET_LATEST_FAIL],
		promise: () =>
			NotificationService.get({
				includes: [
					"announcement",
					"announcement.region",
					"announcement.city",
				].join(","),
				sort: "-date_created",
				limit: 5,
			}),
	};
}

// export function getNotification() {
// 	return {
// 		types: [
// 			GET_NOTIFICATIONS,
// 			GET_NOTIFICATIONS_SUCCESS,
// 			GET_NOTIFICATIONS_FAIL,
// 		],
// 		promise: () =>
// 			NotificationService.get({
// 				includes: ["announcement"].join(","),
// 			}),
// 	};
// }

// export function editOffer(id, data) {
// 	return {
// 		types: [EDIT_OFFER, EDIT_OFFER_SUCCESS, EDIT_OFFER_FAIL],
// 		promise: () => AnnouncementService.edit(id, data),
// 	};
// }

export default reducer;
