import axios from "axios";
import { API_HOST, API_URL } from "./env";

const baseURL = API_HOST
	? `${window.location.protocol}//${API_HOST}/`
	: API_URL;

const api = axios.create({
	baseURL,
	// responseType: "json",
	transformResponse: [
		...axios.defaults.transformResponse,
		function (data) {
			try {
				if (typeof data === "string") {
					JSON.parse(data);
				}
			} catch (err) {
				throw Error(err);
			}
			return data;
		},
	],
});

export { api };
export default axios;
