// import cookie from "react-cookies";
// import UserService from "../../services/UserService";
import { api } from "../../config/axios";
// import {serviceWorkerContext} from "../../libs/react-hook-use-service-worker";

const SET = "auth/SET";

const LOGIN = "auth/LOGIN";
const LOGIN_SUCCESS = "auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "auth/LOGIN_FAIL";

const LOAD = "auth/LOAD";
const LOAD_SUCCESS = "auth/LOAD_SUCCESS";
const LOAD_FAIL = "auth/LOAD_FAIL";

const LOGOUT = "auth/LOGOUT";
const LOGOUT_SUCCESS = "auth/LOGOUT_SUCCESS";
const LOGOUT_FAIL = "auth/LOGOUT_FAIL";

// const SET_PROFILE = "auth/SET_PROFILE";
// const SET_PROFILE_SUCCESS = "auth/SET_PROFILE_SUCCESS";
// const SET_PROFILE_FAIL = "auth/SET_PROFILE_FAIL";

const UPDATE_PROFILE_DATA = "auth/UPDATE_PROFILE_DATA";
const UPDATE_PROFILE_AVATAR = "auth/UPDATE_PROFILE_AVATAR";

const initial = {
	// adm: {
	// 	loaded: false,
	// 	loading: true,
	// 	data: {},
	// 	token: null,
	// },
	// crd: {
	// 	loaded: false,
	// 	loading: true,
	// 	data: {},
	// 	token: null,
	// },
	// agt: {
	// 	loaded: false,
	// 	loading: true,
	// 	data: {},
	// 	token: null,
	// },
	// adm: {},
	// crd: {},
	// agt: {},
	loaded: false,
	loading: true,
	data: null,
	token: null,
};

function reducer(state = initial, action) {
	// if (action.type.startsWith("auth/")) {
	// 	console.log("redux [auth]", { state, action });
	// }

	switch (action.type) {
		case SET:
			return {
				...state,
				// [action.role]: {
				// ...state[action.role],
				...action.state,
				// },
			};
		case LOGIN:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				whileLogging: true,
				// },
			};

		case LOGIN_SUCCESS:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				whileLogging: false,
				token: action.result.data.data.token,
				data: action.result.data.data.user,
				// },
			};

		case LOGIN_FAIL:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				whileLogging: false,
				// },
			};

		case LOAD:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				loading: true,
				error: null,
				// },
			};

		case LOAD_SUCCESS:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				loading: false,
				loaded: true,
				data: action.result.data.data,
				// },
			};

		case LOAD_FAIL:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				loading: false,
				error: action.error,
				// },
			};

		case LOGOUT:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				loading: true,
				// },
			};
		case LOGOUT_SUCCESS:
			return {
				...state,
				// [action.role]: {
				// 	...state[action.role],
				data: null,
				token: null,
				loading: false,
				loaded: true,
				error: null,
				// },
			};
		case LOGOUT_FAIL:
			return {
				...state,
				// [action.role]: {
				// ...state[action.role],
				loading: false,
				// },
			};
		// case SET_PROFILE:
		// 	return {
		// 		...state,
		// 		[action.role]: {
		// 			...state[action.role],
		// 			whileProfileSaving: true,
		// 		},
		// 	};

		// case SET_PROFILE_SUCCESS:
		// 	return {
		// 		...state,
		// 		[action.role]: {
		// 			...state[action.role],
		// 			whileProfileSaving: false,
		// 			data: {
		// 				...state[action.role].data,
		// 				profile: {
		// 					...state[action.role].data.profile,
		// 					...action.result.data.data,
		// 				},
		// 			},
		// 		},
		// 	};

		// case SET_PROFILE_FAIL:
		// 	return {
		// 		...state,
		// 		[action.role]: {
		// 			...state[action.role],
		// 			whileProfileSaving: false,
		// 		},
		// 	};

		case UPDATE_PROFILE_DATA:
			return {
				...state,
				// [action.role]: {
				// ...state[action.role],
				data: {
					// ...state[action.role].data,
					...state.data,
					profile: {
						// ...state[action.role].data.profile,
						...state.data.profile,
						...action.result.data.data,
					},
				},
				// },
			};

		case UPDATE_PROFILE_AVATAR:
			return {
				...state,
				// [action.role]: {
				// ...state[action.role],
				data: {
					// ...state[action.role].data,
					...state.data,
					profile: {
						// ...state[action.role].data.profile,
						...state.data.profile,
						...action.result.data.data,
					},
				},
				// },
			};

		default:
			return state;
	}
}

// export function authorize(login, ) {
// 	console.log("authorize");
// 	return {
// 		types: [AUTHORIZE, AUTHORIZE_SUCCESS, AUTHORIZE_FAIL],
// 		promise: () => api.get("/login"),
// 	};
// }

export function setAuth(state) {
	return {
		type: SET,
		state,
	};
}

export function login(data) {
	return {
		types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
		promise: api => api.post("/login", data),
	};
}

export function load(token) {
	return {
		types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
		promise: () =>
			api.get("/load", {
				// params: {
				// 	"_token": token,
				// },
				headers: {
					"Authorization": `Bearer ${token}`,
				},
			}),
	};
}

export function updateProfileData(result) {
	return {
		type: UPDATE_PROFILE_DATA,
		result,
	};
}

export function updateProfileAvatar(result) {
	return {
		type: UPDATE_PROFILE_AVATAR,
		result,
	};
}

export function logout() {
	return {
		types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
		promise: api => api.post("/logout"),
	};
}

export { initial, UPDATE_PROFILE_DATA, UPDATE_PROFILE_AVATAR };
export default reducer;
