import PushSubscriptionService from "../../services/PushSubscriptionService";

const initial = {
	session: {
		email: "",
	},
	pushSubscription: {
		pending: false,
		loaded: false,
		data: null,
	},
};

const SET_GLOBAL = "global/SET";

const LOAD_PUSHSUBSCRIPTION = "global/LOAD_PUSHSUBSCRIPTION";
const LOAD_PUSHSUBSCRIPTION_SUCCESS = "global/LOAD_PUSHSUBSCRIPTION_SUCCESS";
const LOAD_PUSHSUBSCRIPTION_FAIL = "global/LOAD_PUSHSUBSCRIPTION_FAIL";

const SAVE_PUSHSUBSCRIPTION = "global/SAVE_PUSHSUBSCRIPTION";
const SAVE_PUSHSUBSCRIPTION_SUCCESS = "global/SAVE_PUSHSUBSCRIPTION_SUCCESS";
const SAVE_PUSHSUBSCRIPTION_FAIL = "global/SAVE_PUSHSUBSCRIPTION_FAIL";

const UNSUB_PUSHSUBSCRIPTION = "global/UNSUB_PUSHSUBSCRIPTION";
const UNSUB_PUSHSUBSCRIPTION_SUCCESS = "global/UNSUB_PUSHSUBSCRIPTION_SUCCESS";
const UNSUB_PUSHSUBSCRIPTION_FAIL = "global/UNSUB_PUSHSUBSCRIPTION_FAIL";

function reducer(state = initial, action) {
	// if (action.type.startsWith("global/")) {
	// 	console.log("redux [global]", { state, action });
	// }

	switch (action.type) {
		case SET_GLOBAL:
			return {
				...state,
				...action.params,
			};
		case LOAD_PUSHSUBSCRIPTION:
			return {
				...state,
				pushSubscription: {
					pending: true,
					data: null,
				},
			};
		case LOAD_PUSHSUBSCRIPTION_SUCCESS:
			return {
				...state,
				pushSubscription: {
					pending: false,
					loaded: true,
					data: action.result.data.data,
				},
			};
		case LOAD_PUSHSUBSCRIPTION_FAIL:
			return {
				...state,
				pushSubscription: {
					pending: false,
					loaded: true,
					data: null,
				},
			};
		case SAVE_PUSHSUBSCRIPTION:
			return {
				...state,
				pushSubscription: {
					pending: true,
				},
			};
		case SAVE_PUSHSUBSCRIPTION_SUCCESS:
			return {
				...state,
				pushSubscription: {
					pending: false,
					loaded: true,
					data: action.result.data.data,
				},
			};
		case SAVE_PUSHSUBSCRIPTION_FAIL:
			return {
				...state,
				// pushSubscription: {
				// 	pending: true,
				// 	loa
				// 	token: null,
				// },
			};
		case UNSUB_PUSHSUBSCRIPTION:
			return {
				...state,
				pushSubscription: {
					...state.pushSubscription,
				},
			};
		case UNSUB_PUSHSUBSCRIPTION_SUCCESS:
			return {
				...state,
				pushSubscription: {
					...state.pushSubscription,
					data: null,
				},
			};
		case UNSUB_PUSHSUBSCRIPTION_FAIL:
			return {
				...state,
				pushSubscription: {
					...state.pushSubscription,
				},
			};

		default:
			return state;
	}
}

function setGlobal(params) {
	return {
		type: SET_GLOBAL,
		params,
	};
}

function loadPushSubscription(token) {
	return {
		types: [
			LOAD_PUSHSUBSCRIPTION,
			LOAD_PUSHSUBSCRIPTION_SUCCESS,
			LOAD_PUSHSUBSCRIPTION_FAIL,
		],
		promise: () =>
			PushSubscriptionService.getByToken(token, {
				check: true,
			}),
	};
}

function savePushSubscription(data, params) {
	return {
		types: [
			SAVE_PUSHSUBSCRIPTION,
			SAVE_PUSHSUBSCRIPTION_SUCCESS,
			SAVE_PUSHSUBSCRIPTION_FAIL,
		],
		promise: () => PushSubscriptionService.subscribe(data, params),
	};
}

function removePushSubscription(token, params) {
	return {
		types: [
			UNSUB_PUSHSUBSCRIPTION,
			UNSUB_PUSHSUBSCRIPTION_SUCCESS,
			UNSUB_PUSHSUBSCRIPTION_FAIL,
		],
		promise: () => PushSubscriptionService.unsubscribe(token, params),
	};
}

export {
	setGlobal,
	loadPushSubscription,
	savePushSubscription,
	removePushSubscription,
};
export default reducer;
