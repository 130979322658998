import { combineReducers } from "redux";
// import { withReduxStateSync } from "redux-state-sync";
import { reducers as sharedStateReducers } from "../../libs/redux-shared-state";
// import agents from "./agents";
import auth from "./auth";
import offer from "./offer";
import global from "./global";
import notification from "./notification";
// import offers from "./offers";
// import region from "./region";

const rootReducer = combineReducers({
	// agents,
	auth,
	offer,
	global,
	notification,
	...sharedStateReducers,
	// global,
	// offers,
	// region,
});

export default rootReducer;
// export default withReduxStateSync(rootReducer);
