import i18next from "../config/i18next";

export default ({ content, data, type }) => {
	const parts = content.split("|");
	const messageKey = parts[0];
	const messageAdditionalData = { ...(data || {}) };
	if (parts[1]) {
		parts[1].split("&&").forEach(additionalData => {
			const prop = additionalData.split("=");
			messageAdditionalData[prop[0]] = prop[1];
		});
	}
	return {
		severity: type || "error",
		message: {
			key: messageKey,
			data: messageAdditionalData,
		},
		detail: i18next.t(messageKey, messageAdditionalData),
	};
};
