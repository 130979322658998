import React from "react";
import "./Splash.scss";

export default () => (
	<div className="splash-screen">
		<div className="splash-container">
			<div className="splash-double-bounce1"></div>
			<div className="splash-double-bounce2"></div>
		</div>
	</div>
);
