import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import { Container as GTMContainer } from "./libs/google-analitics";

import { ToastContainer } from "./components/Toast";

import "primereact/resources/primereact.min.css";
import "./theme/saga.scss";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "./App.scss";
import "./primereact.scss";

import PrimeReact from "primereact/api";
import { Helmet } from "react-helmet";

import "./config/axios";
// import FloatingFocus from "@q42/floating-focus-a11y";

import configureStore from "./store/configureStore";
import i18next from "./config/i18next";
import { getDefault } from "./utils/helmet";
import { IS_DEV } from "./config/env";
import Splash from "./components/Splash/Splash";
import messageResponseIterception from "./helpers/axios/messageResponseIterception";
import { api } from "./config/axios";
import "./libs/logrocket";

PrimeReact.ripple = true;

// new FloatingFocus(document.getElementById("root"));

const toastOptions = {
	autoClose: 3000,
	draggable: true,
	draggablePercent: 25,
	pauseOnFocusLoss: false,
};

const store = configureStore();

const AppContent = lazy(() =>
	IS_DEV ? import("./AppDev") : import("./AppProd")
);

const App = () => {
	// check localStorage is enabled
	try {
		if (!window.localStorage) {
			return "The browser 'local storage' functionality is required for the proper operation of the application.";
		}
	} catch (err) {
		return "The browser 'local storage' functionality is required for the proper operation of the application.";
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	useEffect(() => {
		api.interceptors.response.use(...messageResponseIterception);
	}, []);

	return (
		<BrowserRouter>
			<I18nextProvider i18n={i18next}>
				<ReduxProvider store={store}>
					<Helmet defaultTitle={getDefault()} />
					<GTMContainer>
						<Suspense fallback={<Splash />}>
							<AppContent />
						</Suspense>
					</GTMContainer>
					<ToastContainer {...toastOptions} />
				</ReduxProvider>
			</I18nextProvider>
		</BrowserRouter>
	);
};

export default App;
