import React, { createContext, useContext, useEffect, useState } from "react";
import { GOOGLE_GTAG_KEY } from "../config/env";

const Context = createContext({
	instance: null,
});

const Provider = ({ instance, children }) => (
	<Context.Provider value={{ instance }}>{children}</Context.Provider>
);

const useGA = () => {
	const { instance } = useContext(Context);
	return { instance };
};

const Container = ({ children }) => {
	const [instance, setInstance] = useState(null);

	useEffect(() => {
		async function load() {
			if (GOOGLE_GTAG_KEY) {
				import(/* webpackChunkName: "react-ga" */ "react-ga").then(
					({ default: ReactGA }) => {
						setInstance(ReactGA);
					}
				);
			}
		}
		load();
	}, []);

	if (!instance) {
		return children;
	}

	return <Provider instance={instance}>{children}</Provider>;
};

export { Context, Provider, useGA, Container };
