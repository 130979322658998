import { api } from "../config/axios";
import formDataCreator from "../helpers/formDataCreator";

const apiPrefix = "/push_subscription";

const subscribe = ({ endpoint, p256dh, auth }, params) => {
	const formData = formDataCreator({
		endpoint,
		p256dh,
		auth,
	});
	return api.post(`${apiPrefix}`, formData, { params });
};

const getByToken = (token, params) => {
	return api.get(`${apiPrefix}/${token}`, { params });
};

const unsubscribe = (token, params) => {
	return api.delete(`${apiPrefix}/${token}`, { params });
};

export default { subscribe, getByToken, unsubscribe };
