import { toast } from "../../components/Toast";
import messageTransformer from "../../utils/messageTransformer";
import i18next from "../../config/i18next";

const toastGlobalMessages = errors => {
	// show global messages
	errors
		.filter(
			item =>
				typeof item === "string" ||
				(typeof item === "object" &&
					(!item.target || item.target === "global"))
		)
		.map(item => {
			if (typeof item === "string") {
				return { content: item, target: "global" };
			}
			return item;
		})
		.forEach(item => {
			const transformed = messageTransformer(item);
			const detail = i18next.t(
				`common:${transformed.message.key}`,
				transformed.message.data
			);
			toast(detail, {
				type: transformed.severity,
			});
		});

	// return other messages
	return errors.filter(item => {
		return !(
			typeof item === "string" ||
			(typeof item === "object" &&
				(!item.target || item.target === "global"))
		);
	});
};

const onSuccess = response => {
	// console.log("messageResponseIntercetion onSuccess", { response });
	const errors = response.data?.errors;
	if (errors && errors.length > 0) {
		const restErrors = toastGlobalMessages(errors);
		return {
			...response,
			data: {
				...response.data,
				errors: restErrors,
			},
		};
	}
	return response;
};

const onError = error => {
	// console.log("messageResponseIntercetion onError", { error });
	const { response } = error;
	if (response) {
		const errors = response.data?.errors;
		if (errors && errors.length > 0) {
			const restErrors = toastGlobalMessages(errors);
			return Promise.reject({
				...error,
				response: {
					...response,
					data: {
						...response.data,
						errors: restErrors,
					},
				},
			});
		}
	}
	return Promise.reject(error);
};

export default [onSuccess, onError];
